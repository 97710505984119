import { api, helper } from ":mods";
import { ENDPOINTS } from "../const";
import { AModuleSubModule, SubModuleDetails, SubModulesList } from "../models";
import { submodulesList, submodulesDetails } from "../mocks";

const mock = true;
export async function getSubModulesList(moduleID: number | string) {
  return api.getAuth(ENDPOINTS.submodule_list_with_module_id + moduleID).then((res) => res.data as SubModulesList);
}
export async function getSubModuleDetails(submoduleID: number | string) {
  return api.getAuth(ENDPOINTS.submodule_details_with_submodule_id + submoduleID).then((res) => {
    const data = res.data as SubModuleDetails;
    data.data.materials.sort((a, b) => a.order - b.order);
    return data;
  });
}

export async function createSubModule(moduleID: number | string, data: AModuleSubModule): Promise<any> {
  return api.postAuth(ENDPOINTS.submodule_list_with_module_id + moduleID, { ...data, module: moduleID }).then((res) => {
    return Promise.resolve(res.data.data);
  });
}
export async function updateSubModule(submoduleID: number | string, data: AModuleSubModule): Promise<any> {
  return api.putAuth(ENDPOINTS.submodule_details_with_submodule_id + submoduleID, data).then((res) => res.data);
}
export async function deleteSubModules(ids: number[]): Promise<{ id: number; index: number; error?: string }[]> {
  return new Promise(async (res, rej) => {
    const result = [] as { id: number; index: number; error?: string }[];
    for (const id of ids) {
      const index = ids.indexOf(id);
      // result.push({ id, index });
      await api
        .delAuth(ENDPOINTS.submodule_details_with_submodule_id + id)
        .then((res) => {
          result.push({ id, index });
        })
        .catch((err) => {
          result.push({ id, index, error: err.response.data.error });
        });
    }
    res(result);
  });
}
